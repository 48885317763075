import React from 'react'
import Footer from "../Footer";
import Parallax from '../Parallax';
import Projectpage from '../Projectpage';

function Works() {
  return (
<>
<Parallax />
<Projectpage />
<Footer />
</>
  )
}

export default Works