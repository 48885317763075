import React from 'react'
import Contactme from '../Contactme';
import Footer from "../Footer";

function Contact() {
  return (
<>
<Contactme/>
<Footer />
</>
  )
}

export default Contact