import React from 'react'
import Footer from "../Footer";
import Portfoliopage from '../Portfoliopage';

function Portfolio() {
  return (
<>
<Portfoliopage />
<Footer />
</>
  )
}

export default Portfolio